<template>
    <div class="ziliaoAdd">
        <div id="subdiv" >
            <Upload
                ref="uploadref"
                type="drag"
                action="/api/params/import"
                :data="{
                    companyid: this.companyid
                }"
                :max-size= "51200"
                :format="['xls','xlsx']"
                :on-success="handleImportSuccess"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                v-show="editid==null"
                >
                <div style="padding: 20px 0;background:rgb(247, 246, 235);">
                    <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                    <p>点击或拖拽车辆参数文件(xls文件)</p>
                </div>
            </Upload>
            

            <Card style="width: 100%;margin-top: 25px;" v-if='paramdata !=null'>
                <p slot="title">
                    <Icon type="ios-film-outline"></Icon>
                    车型参数
                </p>
                <Form ref="paramAddForm" :model="paramdata" :rules="ruleInline" :disabled="action=='view'" label-position="left" :label-width="300" style="margin:5px;width: 80%;background:rgb(247, 246, 235);">
                    <FormItem  label="0.1.1 中文商标:">
                        <i-input type="text" v-model="paramdata.glbBikePara.cnbrand">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.1.2 英文商标:">
                        <i-input type="text" v-model="paramdata.glbBikePara.enbrand">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.1.3 图形商标:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'imgbrand'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                    </Upload>
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.imagebrand)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.imagebrand=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.imagebrand)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>

                    </FormItem>
                    <FormItem  label="0.1.4 中文厂标:">
                        <i-input type="text" v-model="paramdata.glbBikePara.cnchangbiao">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.1.5 英文厂标:">
                        <i-input type="text" v-model="paramdata.glbBikePara.enchangbiao">
                        </i-input>
                    </FormItem>
                    <FormItem prop="modelno"  label="0.2 产品型号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.modelno" :disabled="editid!=null">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.2.1 商业名称（如有）:">
                        <i-input type="text" v-model="paramdata.glbBikePara.cnmodelno" :disabled="editid!=null">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="0.2.2 产品名称:">
                        <i-input type="text" v-model="paramdata.glbBikePara.productno" :disabled="editid!=null">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.3 产品型号的标识方法:">
                        <i-input type="text" v-model="paramdata.glbBikePara.biaoshifangfa">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.3.1 产品型号的标识位置:">
                        <i-input type="text" v-model="paramdata.glbBikePara.biaoshiweizhi">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.4 生产者（制造商）的名称:">
                        <i-input type="text" v-model="paramdata.glbBikePara.manufacturer">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="0.4.1 生产者（制造商）的地址:">
                        <i-input type="text" v-model="paramdata.glbBikePara.manufactureraddr">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.4.2 生产企业（生产厂）:">
                        <i-input type="text" v-model="paramdata.glbBikePara.producer">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.4.3 生产企业（生产厂）的地址:">
                        <i-input type="text" v-model="paramdata.glbBikePara.produceraddr">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.5 整车编码:">
                        <i-input type="text" v-model="paramdata.glbBikePara.vin">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.6 CCC申请编号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.cccno">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.7 委托人名称:">
                        <i-input type="text" v-model="paramdata.glbBikePara.weituoren">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.7.1 委托人的地址:">
                        <i-input type="text" v-model="paramdata.glbBikePara.weituorenaddr">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="0.8 单元代号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.danyuandaihao">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.9 工厂编号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.gongchangno">
                        </i-input>
                    </FormItem>
                    <FormItem  label="0.10 车辆制造国:">
                        <i-input type="text" v-model="paramdata.glbBikePara.country">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1 车辆总体结构:">
                        <i-input type="text" v-model="paramdata.glbBikePara.structure">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.1 典型车辆的照片（前左45°后右45°）:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'bikeclassicimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>    
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5">
                                <div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.bikeclassicimg)">查看</div>
                            </Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.bikeclassicimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.bikeclassicimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem  label="1.2 车辆外形简图:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                ref="uploadref"
                                action="/api/files/checkdept/param/infiles/upload/"
                                :data="{
                                    companyid: this.companyid,
                                    modelno: this.paramdata.glbBikePara.modelno,
                                    fileclass: 'bikesimpleimg'
                                }"
                                :max-size= "51200"
                                :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :show-upload-list=false
                                >
                                <Button >上传</Button>
                            </Upload>     
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.bikesimpleimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.bikesimpleimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.bikesimpleimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                                                         
                    </FormItem>
                    <FormItem  label="1.3 完整车辆的尺寸图:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                ref="uploadref"
                                action="/api/files/checkdept/param/infiles/upload/"
                                :data="{
                                    companyid: this.companyid,
                                    modelno: this.paramdata.glbBikePara.modelno,
                                    fileclass: 'bikesizeimg'
                                }"
                                :max-size= "51200"
                                :format="['jpg','jpeg','png','bmp','pdf']"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :show-upload-list=false
                                >
                                <Button >上传</Button>
                            </Upload>      
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.bikesizeimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.bikesizeimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.bikesizeimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>
                    </FormItem>    
                    <FormItem  label="1.3.1 长(mm):">
                        <i-input type="text" v-model="paramdata.glbBikePara.chang">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.3.2 宽(mm):">
                        <i-input type="text" v-model="paramdata.glbBikePara.kuan">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.3.3 高(mm):">
                        <i-input type="text" v-model="paramdata.glbBikePara.gao">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.3.4 前后轮中心距(mm):">
                        <i-input type="text" v-model="paramdata.glbBikePara.wheeltrack">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="1.3.5 除车把、脚蹬部分外的车体宽度(mm):">
                        <i-input type="text" v-model="paramdata.glbBikePara.bodywidth">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.4 电机的布置:">
                        <i-input type="text" v-model="paramdata.glbBikePara.motorlayout">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.5 鞍座型式:">
                        <i-input type="text" v-model="paramdata.glbBikePara.saddletype">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="1.5.1 鞍座尺寸:">
                        <i-input type="text" v-model="paramdata.glbBikePara.saddlesize">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.5.2 鞍座最大高度（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikePara.saddlemaxheight">
                        </i-input>
                    </FormItem>
                    <FormItem  label="1.6 衣架平坦部分最大宽度（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikePara.hangermaxwidth">
                        </i-input>
                    </FormItem>
                    <FormItem  label="2 质量:">
                    </FormItem>    
                    <FormItem  label="2.1 装配完整的电动自行车的整车质量(kg):">
                        <i-input type="text" v-model="paramdata.glbBikePara.totalweight">
                        </i-input>
                    </FormItem>
                    <FormItem  label="2.2 载重量（kg）:">
                        <i-input type="text" v-model="paramdata.glbBikePara.deadweight">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3 电动控制系统:">

                    </FormItem>    
                    <FormItem  label="3.0 电动机额定连续输出功率总和（W）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorpowertotal">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.0.1 电动机数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motornum">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.0.2 驱动方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motordrivetype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1 电动机:">
                    </FormItem>      
                    <FormItem  label="3.1.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.2 认证证书编号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorcerno">
                        </i-input>
                    </FormItem>         
                    <FormItem  label="3.1.3 型式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motortype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.4 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motormodel">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.1.5 电动机商标:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorbrand">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.6 额定电压(V):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorvoltage">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.7 额定转速(r/min):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorspeed">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.8 额定连续输出功率(W):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motoroutputpower">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.1.9 工作原理:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorprinciple">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.1.10 工作方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorworktype">
                        </i-input>
                    </FormItem>         
                    <FormItem  label="3.1.11 电动机图样:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                ref="uploadref"
                                action="/api/files/checkdept/param/infiles/upload/"
                                :data="{
                                    companyid: this.companyid,
                                    modelno: this.paramdata.glbBikePara.modelno,
                                    fileclass: 'motorimg'
                                }"
                                :max-size= "51200"
                                :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :show-upload-list=false
                                >
                                <Button >上传</Button>
                            </Upload>    
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaEc.motorimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaEc.motorimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaEc.motorimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                                      
                    </FormItem>
                    <FormItem  label="3.1.12 电动机限流保护电流(A):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motorcurrent">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.1.13 电动机过载保护方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.motoroverprotect">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2 蓄电池:">
                    </FormItem>    
                    <FormItem  label="3.2.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellmodel">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.2.3 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.celltype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.4 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellnum">
                        </i-input>
                    </FormItem>         
                    <FormItem  label="3.2.5 容量(Ah):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellvolume">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.6 位置:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellpos">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.2.7 总质量(kg):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellweight">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.8 锂离子蓄电池保护装置:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellprotect">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.9 蓄电池图样:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'cellimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>     
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaEc.cellimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaEc.cellimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaEc.cellimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem  label="3.2.10 电池连接方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellconnecttype">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.2.11 组合一致性:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellmixconsi">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.2.12 标称电压（V）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cellvoltage">
                        </i-input>
                    </FormItem>         
                    <FormItem  label="3.3 控制器:">
                    </FormItem>
                    <FormItem  label="3.3.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlowner">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.3.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlmodel">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.3.3 电机控制器控制类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctltype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.3.4 控制器过流保护型式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctloverprotect">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.3.5 控制器过流断电保护时间(ms):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlprotecttime">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.3.6 控制器最大输入电流(A):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlmaxinput">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.3.7 控制器额定输入电流(A):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlinput">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.3.8 无线电骚扰抑制装置说明:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlblockdesc">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.3.9 欠压保护值(V):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlvoltageprotect">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="3.3.10 过流保护值(A):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlcurrentprotect">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.3.11 防失控保护功能（有/无）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlprotectfun">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.3.12 防篡改措施描述:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.ctlantitamper">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.4 转换器:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvt">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.4.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvtowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.4.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvtmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.4.3 额定输入电压/电流（V/A）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvtinput">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.4.4 额定输出电压/电流（V/A）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvtoutput">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="3.4.5 转化效率:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvteffi">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.4.6 工作电压范围（V）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.cvtvoltagerange">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.5 充电器:">
                    </FormItem>
                    <FormItem  label="3.5.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargowner">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.5.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargmodel">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.5.3 额定输入电压/电流（V/A）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.charginput">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.5.4 额定输出电压/电流（V/A）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargoutput">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.5.5 转化效率:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargeffi">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="3.5.6 车辆与充电器连接保护:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargconnprotect">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.5.7 短路保护:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargotp">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="3.5.8 错接保护:">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargeip">
                        </i-input>
                    </FormItem>
                    <FormItem  label="3.5.9 过热保护值(℃):">
                        <i-input type="text" v-model="paramdata.glbBikeParaEc.chargohp">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="4 传动装置:">
                    </FormItem>
                    <FormItem  label="4.1 传动系统的图样:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                ref="uploadref"
                                action="/api/files/checkdept/param/infiles/upload/"
                                :data="{
                                    companyid: this.companyid,
                                    modelno: this.paramdata.glbBikePara.modelno,
                                    fileclass: 'transimg'
                                }"
                                :max-size= "51200"
                                :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :on-format-error="handleFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :show-upload-list=false
                                >
                                <Button >上传</Button>
                            </Upload>      
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaTrans.transimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaTrans.transimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaTrans.transimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>   
                    <FormItem  label="4.2 传感器:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.transsensor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.3 传动型式（机械式/液力式/电力式）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.transclass">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="4.3.1 传动方式（链条/轴/其它）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.transtype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.4 变速器型式（自动/人工）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.transspdclass">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="4.5 传动比:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.transratio">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.6 最高车速:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.maxspeed">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="4.6.0 最高设计车速(km/h):">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.maxspeeddesign">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.6.1 最高车速相应档位:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.maxgear">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="4.6.2 续行里程(km):">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.maxmile">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.6.3 百公里电耗（kW·h/100km）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.powerconsume">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="4.7 仪表总成:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.meter">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.7.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.meterowner">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="4.7.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.metermodel">
                        </i-input>
                    </FormItem>
                    <FormItem  label="4.7.3 型式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaTrans.metertype">
                        </i-input>
                    </FormItem>     
                    <FormItem  label="4.7.4 完整系统的照片或图样:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'sysimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>    
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaTrans.sysimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaTrans.sysimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaTrans.sysimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>
                    <FormItem  label="5 车架:">
                    </FormItem>   
                    <FormItem  label="5.1 完整车架/前叉/车把组合件图:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'frmimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>    
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaBrakfrm.frmimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaBrakfrm.frmimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaBrakfrm.frmimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>
                    <FormItem  label="5.1.1 车架:">
                    </FormItem>       
                    <FormItem  label="5.1.1.1 车架生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.frmowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.1.2 前叉:">
                    </FormItem>  
                    <FormItem  label="5.1.2.1 前叉生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.fforkowner">
                        </i-input>
                    </FormItem>  
                    <FormItem  label="5.1.3 车把:">
                    </FormItem>  
                    <FormItem  label="5.1.3.1 车把生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.handleowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.2 中轴和曲柄:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.bb">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="5.3 脚蹬:">
                    </FormItem>
                    <FormItem  label="5.3.1 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.pedalmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="5.3.2 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.pedalowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.3.3 脚蹬间隙:">
                    </FormItem>   
                    <FormItem  label="5.3.3.1 地面距离（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.pedalgapground">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.3.3.2 足趾间隙（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.pedalgapfoot">
                        </i-input>
                    </FormItem>       
                    <FormItem  label="5.3.4 防滑措施:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.pedalantiskid">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.4 轮胎:">
                    </FormItem>   
                    <FormItem  label="5.4.1 前轮:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrefrontowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.4.1.1 厂定轮胎气压(kPa):">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrefrontpar">
                        </i-input>
                    </FormItem>           
                    <FormItem  label="5.4.1.2 轮胎规格:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrefrontspec">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.4.2 后轮:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrebackowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="5.4.2.1 厂定轮胎气压(kPa):">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrebackpar">
                        </i-input>
                    </FormItem>           
                    <FormItem  label="5.4.2.2 轮胎规格:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.tyrebackspec">
                        </i-input>
                    </FormItem>
                    <FormItem  label="6 制动:">
                    </FormItem>       
                    <FormItem  label="6.1 制动装置的图样:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'brakeimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>  
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaBrakfrm.brakeimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaBrakfrm.brakeimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaBrakfrm.brakeimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>
                    <FormItem  label="6.2 制动器:">
                    </FormItem>   
                    <FormItem  label="6.2.1 前制动器:">
                    </FormItem>
                    <FormItem  label="6.2.1.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.fbrakeowner">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="6.2.1.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.fbrakemodel">
                        </i-input>
                    </FormItem>
                    <FormItem  label="6.2.1.3 型式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.fbraketype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="6.2.1.4 操纵方式描述:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.fbrakeoptype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="6.2.2 后制动器:">
                    </FormItem>
                    <FormItem  label="6.2.2.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.bbrakeowner">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="6.2.2.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.bbrakemodel">
                        </i-input>
                    </FormItem>
                    <FormItem  label="6.2.2.3 型式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.bbraketype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="6.2.2.4 操纵方式描述:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.bbrakeoptype">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7 照明和光信号装置:">
                    </FormItem>       
                    <FormItem  label="7.1 照明和光信号装置的位置图:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'glbBikeParaLight.posimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload> 
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaLight.posimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaLight.posimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaLight.posimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem  label="7.1.1 对特殊车辆的附加要求:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.tsclfjyq">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.1.2 照明和光信号装置中所用的电子部件的简要描述:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.dzbjjyms">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2 前灯:">
                    </FormItem>    
                    <FormItem  label="7.2.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.2.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightnum">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.4 光源数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightsourcenum">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.2.5 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.6 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flighttype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.2.7 额定电压(V):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightvoltage">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.8 额定功率(W):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightpower">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.2.9 光源规格型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightsourcespec">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.10 标准光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.2.11 识别码:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightsbm">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.12 LED光源模块数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightmksl">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.2.13 目标光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightmbgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.2.14 光源模块外廓尺寸（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightmkcc">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.2.15 是否为低UV辐射光源模块:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.flightluv">
                        </i-input>
                    </FormItem>  
                    <FormItem  label="7.3 后灯:">
                    </FormItem>    
                    <FormItem  label="7.3.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.3.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightnum">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.4 光源数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightsourcenum">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.3.5 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.6 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blighttype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.3.7 额定电压(V):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightvoltage">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.8 额定功率(W):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightpower">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.3.9 光源规格型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightsourcespec">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.10 标准光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.3.11 识别码:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightsbm">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.12 LED光源模块数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightmksl">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.3.13 目标光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightmbgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.3.14 光源模块外廓尺寸（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightmkcc">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.3.15 是否为低UV辐射光源模块:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.blightluv">
                        </i-input>
                    </FormItem>     
                    <FormItem  label="7.4 其他类型灯具:">
                        
                    </FormItem>    
                    <FormItem  label="7.4.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.4.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightnum">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.4 光源数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightsourcenum">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.4.5 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.6 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olighttype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.4.7 额定电压(V):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightvoltage">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.8 额定功率(W):">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightpower">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.4.9 光源规格型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightsourcespec">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.10 标准光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.4.11 识别码:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightsbm">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.12 LED光源模块数量:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightmksl">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.4.13 目标光通量（lm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightmbgtl">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.4.14 光源模块外廓尺寸（mm）:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightmkcc">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.4.15 是否为低UV辐射光源模块:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.olightluv">
                        </i-input>
                    </FormItem>                                     
                    <FormItem  label="7.5 反射器:">
                    </FormItem>   
                    <FormItem  label="7.5.1 后反射器:">
                    </FormItem>
                    <FormItem  label="7.5.1.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefowner">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.5.1.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.1.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefnum">
                        </i-input>
                    </FormItem>                    
                    <FormItem  label="7.5.1.4 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.1.5 形状:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefshape">
                        </i-input>
                    </FormItem>     
                    <FormItem  label="7.5.1.5.1 结构特征:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefstrucure">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.1.5.2 材料:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.brefstuff">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.1.5.3 排列方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.breflayout">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.5.1.6 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.breftype">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.2 侧反射器:">
                    </FormItem>
                    <FormItem  label="7.5.2.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefowner">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.5.2.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.2.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefnum">
                        </i-input>
                    </FormItem>                    
                    <FormItem  label="7.5.2.4 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.2.5 形状:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefshape">
                        </i-input>
                    </FormItem>     
                    <FormItem  label="7.5.2.5.1 结构特征:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefstrucure">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.2.5.2 材料:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.srefstuff">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.2.5.3 排列方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.sreflayout">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.5.3 脚蹬反射器:">
                    </FormItem>
                    <FormItem  label="7.5.3.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefowner">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="7.5.3.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.3.3 数目:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefnum">
                        </i-input>
                    </FormItem>                    
                    <FormItem  label="7.5.3.4 颜色:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefcolor">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.3.5 形状:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefshape">
                        </i-input>
                    </FormItem>     
                    <FormItem  label="7.5.3.5.1 结构特征:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefstrucure">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="7.5.3.5.2 材料:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.frefstuff">
                        </i-input>
                    </FormItem>
                    <FormItem  label="7.5.3.5.3 排列方式:">
                        <i-input type="text" v-model="paramdata.glbBikeParaLight.freflayout">
                        </i-input>
                    </FormItem>                                         
                    <FormItem  label="8 装备:">
                    </FormItem>
                    <FormItem  label="8.1 标识、控制器、信号装置 、指示器布置的图样或照片:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'glbBikeParaBrakfrm.devimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf','zip']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload> 
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaBrakfrm.devimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaBrakfrm.devimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaBrakfrm.devimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>  
                    <FormItem  label="8.2 铭牌在车架上的固定方式和位置:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.devcardpos">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="8.2.1 铭牌位置的图样或照片:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'glbBikeParaBrakfrm.devcardimg'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','png','bmp','pdf','zip','doc','docx','xls','xlsx']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikeParaBrakfrm.devcardimg)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikeParaBrakfrm.devcardimg=''"
                                >
                                <Button type="error" ghost :disabled="isnull(paramdata.glbBikeParaBrakfrm.devcardimg)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                          
                    </FormItem>
                    <FormItem  label="8.2.2 整车编码的位置:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.devvinpos">
                        </i-input>
                    </FormItem>    
                    <FormItem  label="8.3 鸣号装置:">
                    </FormItem>   
                    <FormItem  label="8.3.1 生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.speakerowner">
                        </i-input>
                    </FormItem>
                    <FormItem  label="8.3.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.speakermodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="8.3.3 类型:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.speakertype">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="8.4 车速提示音装置:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.spdnotify">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="8.4.1 车速提示音装置生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.spdnotifyowner">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="8.4.2 型号:">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.spdnotifymodel">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="8.4.4 警示速度(km/h):">
                        <i-input type="text" v-model="paramdata.glbBikeParaBrakfrm.spdnotifyspd">
                        </i-input>
                    </FormItem>      
                    <FormItem  label="9 阻燃材料:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'retardant'
                                    }"
                                    :max-size= "51200"
                                    :format="['xls','xlsx']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    <Button >上传</Button>
                                </Upload>
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.retardant)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.retardant=''"
                                >
                                    <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.retardant)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>
                    </FormItem>  
                    <FormItem  label="10 防火材料:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'fireproof'
                                    }"
                                    :max-size= "51200"
                                    :format="['xls','xlsx']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    
                                        <Button style="margin-top: 10px;">上传</Button>
                                        <div >
                                            <span style="color:red;">(excel文件)</span>
                                        </div>
                                </Upload>
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.fireproof)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.fireproof=''"
                                >
                                    <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.fireproof)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                        
                    </FormItem>    
                    <FormItem  label="11 其他补充说明:">
                    </FormItem> 
                    <FormItem  label="11.1 使用说明书:">
                        <Row type="flex" style="padding: 5px 0;" align="middle" >
                            <Col span="12" style="background: #e9ebe7;">
                                <Upload
                                    ref="uploadref"
                                    action="/api/files/checkdept/param/infiles/upload/"
                                    :data="{
                                        companyid: this.companyid,
                                        modelno: this.paramdata.glbBikePara.modelno,
                                        fileclass: 'manual'
                                    }"
                                    :max-size= "51200"
                                    :format="['jpg','jpeg','pdf','doc','docx']"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    :on-format-error="handleFormatError"
                                    :on-exceeded-size="handleMaxSize"
                                    :show-upload-list=false
                                    >
                                    
                                        <Button style="margin-top: 10px;">上传</Button>
                                        <div >
                                            <span style="color:red;"> (word、pdf文件或jpg图片)</span>
                                        </div>
                                </Upload>
                            </Col>
                            <Col span="2"></Col>
                            <Col span="5"><div style="margin: 0 auto;width: 60px;cursor: pointer;color: green;border: 1px solid;" @click="download(paramdata.glbBikePara.manual)">查看</div></Col>
                            <Col span="5">
                                <Poptip
                                confirm
                                title="确定要删除吗?"
                                @on-ok="paramdata.glbBikePara.manual=''"
                                >
                                    <Button type="error" ghost :disabled="isnull(paramdata.glbBikePara.manual)" >删除</Button>
                                </Poptip>
                            </Col>
                        </Row>                          
                    </FormItem> 
                    <FormItem  label="11.2 补充说明一:">
                        <i-input type="text" v-model="paramdata.glbBikePara.supplyone">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="11.3 补充说明三:">
                        <i-input type="text" v-model="paramdata.glbBikePara.supplytwo">
                        </i-input>
                    </FormItem>         
                    <FormItem  label="12.1 鞍座总成生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikePara.saddlesysowner">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="12.2 鞍座总成型号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.saddlesysmodel">
                        </i-input>
                    </FormItem>          
                    <FormItem  label="13.1 中曲链生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikePara.zqlowner">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="13.2 中曲链型号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.zqlmodel">
                        </i-input>
                    </FormItem>   
                    <FormItem  label="14.1 线束生产企业:">
                        <i-input type="text" v-model="paramdata.glbBikePara.harnessowner">
                        </i-input>
                    </FormItem> 
                    <FormItem  label="14.2 线束型号:">
                        <i-input type="text" v-model="paramdata.glbBikePara.harnessmodel">
                        </i-input>
                    </FormItem>                                                           
                    <FormItem  label="" v-if="usercategory=='slave'">
                        <Button type="primary" @click="saveparams('paramAddForm')">保存</Button>
                    </FormItem>                                                        
                </Form>
            </Card>
        </div>
    </div>

  </template>
  
  <script>

  export default {
    name: "ziliaoAdd",
    props: {
    },
    components: {
	},
    data(){
        const validatemodelno = (rule, value, callback) => {
            console.log(value)
            if (value == '' || value==null) {
                return callback(new Error('车辆型号必填！'))
            }  else {
                callback()
            }
        }
        return {
            src:null,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
            paramdata: {
                glbBikePara: {
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    cnbrand: null,
                    enbrand: null,
                    imagebrand: null,
                    cnchangbiao: null, //天津雷克斯科技有限公司,
                    enchangbiao: null, //,
                    modelno: null, //TDT001Z,
                    cnmodelno: null, //亚洲龙,
                    productno: null, //电动自行车,
                    biaoshifangfa: null, //铭牌打刻形式,
                    biaoshiweizhi: null, //铭牌上,
                    manufacturer: null, //天津雷克斯科技有限公司,
                    manufactureraddr: null, //天津市北辰区小淀镇津围公路东(大孚投资集团院内),
                    producer: null, //天津雷克斯科技有限公司,
                    produceraddr: null, //天津市北辰区小淀镇津围公路东(大孚投资集团院内),
                    vin: null, //32022,
                    cccno: null, //,
                    weituoren: null, //天津雷克斯科技有限公司,
                    weituorenaddr: null, //天津市北辰区小淀镇津围公路东(大孚投资集团院内),
                    danyuandaihao: null, //LKS,
                    gongchangno: null, //,
                    country: null, //中国,
                    structure: null, //null,
                    bikeclassicimg: null, //null,
                    bikesimpleimg: null, //null,
                    bikesizeimg: null, //null,
                    chang: null, //1600,
                    kuan: null, //690,
                    gao: null, //1095,
                    wheeltrack: null, //1100,
                    bodywidth: null, //383,
                    motorlayout: null, //轮毂电机,
                    saddletype: null, //鞍座式,
                    saddlesize: null, //268*220,
                    saddlemaxheight: null, //755,
                    hangermaxwidth: null, //170,
                    totalweight: null, //53,
                    deadweight: null, //75,
                    retardant: null, //,
                    fireproof: null, //,
                    manual: null, //null,
                    supplyone: null, //,
                    supplytwo: null, //,
                    zqlowner: null, //null,
                    zqlmodel: null, //null,
                    saddlesysowner: null, //null,
                    saddlesysmodel: null, //null,
                    harnessowner:null,
                    harnessmodel:null,
                    modifyuser: null, //null
                },
                glbBikeParaBrakfrm:{
                    id: null, //null,
                    companyid: null, //null,
                    parentid: null, //null,
                    frm: null, //null,
                    frmimg: null, //null,
                    frmowner: null, //天津欧瑞德自行车有限公司,
                    ffork: null, //,
                    fforkowner: null, //摩单科技（天津）有限公司,
                    handleowner: null, //天津佳亿鼎业车料有限公司,
                    bb: null, //295,
                    pedalmodel: null, //普通,
                    pedalowner: null, //永清县远达金塑制品有限公司,
                    pedalgapground: null, //150,
                    pedalgapfoot: null, //无交点,
                    pedalantiskid: null, //表面采用凹凸不平的表面防滑,
                    tyrefrontowner: null, //徐州汉邦橡胶有限公司,
                    tyrefrontpar: null, //240-310,
                    tyrefrontspec: null, //14×2.75,
                    tyrebackowner: null, //徐州汉邦橡胶有限公司,
                    tyrebackpar: null, //240-310,
                    tyrebackspec: null, //14×2.75,
                    brakeimg: null, //null,
                    fbrakeowner: null, //江都区郭村镇五红星车件厂,
                    fbrakemodel: null, //110,
                    fbraketype: null, //鼓式,
                    fbrakeoptype: null, //手操纵,
                    bbrakeowner: null, //江都区郭村镇五红星车件厂,
                    bbrakemodel: null, //110,
                    bbraketype: null, //鼓式,
                    bbrakeoptype: null, //手操纵,
                    devimg: null, //null,
                    devcardpos: null, //铆接在后平叉处,
                    devcardimg: null, //后平叉处,
                    devvinpos: null, //头管处,
                    speakerowner: null, //天津新思维运动器械科技有限公司,
                    speakermodel: null, //普通喇叭,
                    speakertype: null, //电子式,
                    spdnotify: null, //,
                    spdnotifyowner: null, //高邮市新燕电子电器厂,
                    spdnotifymodel: null, //报警器,
                    spdnotifyspd: null, //≤15,
                },
                glbBikeParaEc:{
                    companyid: null, //null,
                    parentid: null, //null,
                    modelno: null, //null,
                    cnmodelno: null, //null,
                    motorpowertotal: null, //350,
                    motornum: null, //1,
                    motordrivetype: null, //电驱动,
                    motorowner: null, //天津市武清区名都电机厂,
                    motorcerno: null, //2020980401002790,
                    motortype: null, //永磁,
                    motormodel: null, //MD350W,
                    motorbrand: null, //,
                    motorvoltage: null, //48,
                    motorspeed: null, //360,
                    motoroutputpower: null, //350,
                    motorprinciple: null, //直流电动机,
                    motorworktype: null, //S1,
                    motorimg: null, //null,
                    motorcurrent: null, //17±1,
                    motoroverprotect: null, //有过载保护,电流超过其限制时，过载保护起限流作用。,
                    cellowner: null, //秦皇岛市芯驰光电科技有限公司,
                    cellmodel: null, //48-16,
                    cellnum: null, //1,
                    celltype: null, //锂电,
                    cellvolume: null, //16,
                    cellpos: null, //鞍座下方,
                    cellweight: null, //7,
                    cellprotect: null, //,
                    cellimg: null, //null,
                    cellconnecttype: null, //并联+串联,
                    cellmixconsi: null, //≤50mV,
                    cellvoltage: null, //48,
                    ctlowner: null, //江苏中通电子科技有限公司,
                    ctlmodel: null, //48V-6,
                    ctltype: null, //开环控制,
                    ctloverprotect: null, //过流后限流电流,
                    ctlprotecttime: null, //2,
                    ctlmaxinput: null, //25,
                    ctlinput: null, //7,
                    ctlblockdesc: null, //金属外壳屏蔽,
                    ctlvoltageprotect: null, //39±1,
                    ctlcurrentprotect: null, //15±2,
                    ctlprotectfun: null, //有,
                    ctlantitamper: null, //异形螺丝,
                    cvt: null, //N/A,
                    cvtowner: null, //N/A,
                    cvtmodel: null, //N/A,
                    cvtinput: null, //N/A,
                    cvtoutput: null, //N/A,
                    cvteffi: null, //N/A,
                    cvtvoltagerange: null, //N/A,
                    chargowner: null, //深圳市艾特能科技有限公司,
                    chargmodel: null, //LBC481003,
                    charginput: null, //220V/2.2A,
                    chargoutput: null, //54.6V/3.0A,
                    chargeffi: null, //≥85%,
                    chargconnprotect: null, //充电器与车辆连接时无火花产生,
                    chargotp: null, //充电器输出端短路，充电器无输出，短路撤销后充电器可以继续正常工作,
                    chargeip: null, //充电器与输出极性不符的电池组连接，充电器绿灯常亮无输出,
                    chargohp: null, //70±5℃,
                },
                glbBikeParaLight: { //{
                    companyid: null, //null,
                    parentid: null, //null,
                    posimg: null, //null,
                    tsclfjyq: null, //无,
                    dzbjjyms: null, //,
                    flightowner: null, //天津新思维运动器械科技有限公司,
                    flightmodel: null, //XSW-801,
                    flightnum: null, //1,
                    flightsourcenum: null, //1*1,
                    flightcolor: null, //白色,
                    flighttype: null, //LED,
                    flightvoltage: null, //48,
                    flightpower: null, //2.8,
                    flightsourcespec: null, //,
                    flightgtl: null, //,
                    flightsbm: null, //,
                    flightmksl: null, //,
                    flightmbgtl: null, //,
                    flightmkcc: null, //,
                    flightluv: null, //是,
                    blightowner: null, //天津新思维运动器械科技有限公司,
                    blightmodel: null, //XSW-T01,
                    blightnum: null, //1,
                    blightsourcenum: null, //,
                    blightcolor: null, //红色,
                    blighttype: null, //LED,
                    blightvoltage: null, //48,
                    blightpower: null, //0.7,
                    blightsourcespec: null, //,
                    blightgtl: null, //80,
                    blightsbm: null, //,
                    blightmksl: null, //20,
                    blightmbgtl: null, //70lm,
                    blightmkcc: null, //131*31 mm,
                    blightluv: null, //是,
                    olight: null, //true,
                    olightowner: null, //天津新思维运动器械科技有限公司,
                    olightmodel: null, //XSWZX-001,
                    olightnum: null, //2,
                    olightsourcenum: null, //,
                    olightcolor: null, //,
                    olighttype: null, //,
                    olightvoltage: null, //48,
                    olightpower: null, //0.7,
                    olightsourcespec: null, //,
                    olightgtl: null, //,
                    olightsbm: null, //,
                    olightmksl: null, //,
                    olightmbgtl: null, //,
                    olightmkcc: null, //,
                    olightluv: null, //,
                    brefowner: null, //烟台长虹塑料制品有限公司,
                    brefmodel: null, //CHP-8008,
                    brefnum: null, //1,
                    brefcolor: null, //红色,
                    brefshape: null, //异形,
                    brefstrucure: null, //六面体,
                    brefstuff: null, //PMMA,
                    breflayout: null, //六边形,
                    breftype: null, //广角反射器,
                    srefowner: null, //上海汇美塑料制品有限公司,
                    srefmodel: null, //HDP7AK,
                    srefnum: null, //2,
                    srefcolor: null, //黄色,
                    srefshape: null, //异型,
                    srefstrucure: null, //六面体,
                    srefstuff: null, //PMMA,
                    sreflayout: null, //六边形,
                    frefowner: null, //烟台长虹塑料制品有限公司,
                    frefmodel: null, //CHP-001,
                    frefnum: null, //4,
                    frefcolor: null, //null,//
                    frefshape: null, //null,
                    frefstrucure: null, //null,
                    frefstuff: null, //null,
                    freflayout: null, //null,
                },
                glbBikeParaTrans: {
                    companyid: null, //null,
                    parentid: null, //null,
                    transimg: null, //null,
                    transsensor: null, //null,
                    transclass: null, //null,
                    transtype: null, //null,
                    transspdclass: null, //null,
                    transratio: null, //null,
                    maxspeed: null, //null,
                    maxspeeddesign: null, //null,
                    maxgear: null, //null,
                    maxmile: null, //null,
                    powerconsume: null, //null,
                    meter: null, //null,
                    meterowner: null, //null,
                    metermodel:null,
                    metertype: null, //null,
                    sysimg: null,
                },
            },
            ruleInline: {
                modelno: [
                    { validator: validatemodelno, trigger: 'blur' }
                ]
            },
            editid:null,
            action:null
        }
    },
    mounted(){

        this.action = this.$route.query.action
        this.editid = this.$route.query.id
        
        if(this.editid !=null){
            console.log(this.editid)

            this.getparams(this.editid)
        }
    },
    methods:{
        getparams (paramid){
            this.$axios({
                method:'post',
                url:"/api/params/model/get",
                data: {id: paramid},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                console.log(res)
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.paramdata = res.data.data
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this)); 
        },
        saveparams(name){
            if(this.paramdata.glbBikePara.modelno == '' || this.paramdata.glbBikePara.modelno == null){
                this.$Message.error({content:'车辆型号必填！',duration:3})
                return
            }

            for(var p in this.paramdata.glbBikePara){
                if(this.paramdata.glbBikePara[p] == null){
                    delete this.paramdata.glbBikePara[p]
                }
            }
            for(var p in this.paramdata.glbBikeParaBrakfrm){
                if(this.paramdata.glbBikeParaBrakfrm[p] == null){
                    delete this.paramdata.glbBikeParaBrakfrm[p]
                }
            }
            for(var p in this.paramdata.glbBikeParaEc){
                if(this.paramdata.glbBikeParaEc[p] == null){
                    delete this.paramdata.glbBikeParaEc[p]
                }
            }
            for(var p in this.paramdata.glbBikeParaLight){
                if(this.paramdata.glbBikeParaLight[p] == null){
                    delete this.paramdata.glbBikeParaLight[p]
                }
            }
            for(var p in this.paramdata.glbBikeParaTrans){
                if(this.paramdata.glbBikeParaTrans[p] == null){
                    delete this.paramdata.glbBikeParaTrans[p]
                }
            }

            console.log(this.paramdata.glbBikePara)
            console.log(this.paramdata.glbBikeParaBrakfrm)
            console.log(this.paramdata.glbBikeParaEc)
            console.log(this.paramdata.glbBikeParaLight)
            console.log(this.paramdata.glbBikeParaTrans)
            let postdata ={
                companyid :this.companyid,
                ppp : JSON.stringify(this.paramdata)
            }

            let posturl 
            if(this.editid == null){
                posturl = '/api/params/model/add'
            }
            else{
                posturl = '/api/params/model/update'
            }
            this.$axios({
                method:'post',
                url:posturl,
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                console.log(res)
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.$router.go(-1)
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this)); 
        },
        handleImportSuccess(response, file, fileList){
            console.log(response.data)
            this.paramdata = response.data

            this.paramdata.glbBikePara.imagebrand = null
            this.paramdata.glbBikePara.bikeclassicimg = null
            this.paramdata.glbBikePara.bikesimpleimg = null
            this.paramdata.glbBikePara.bikesizeimg = null
            this.paramdata.glbBikeParaEc.motorimg = null
            this.paramdata.glbBikeParaEc.cellimg = null
            this.paramdata.glbBikeParaTrans.transimg = null
            this.paramdata.glbBikeParaTrans.sysimg = null
            this.paramdata.glbBikeParaBrakfrm.frmimg = null
            this.paramdata.glbBikeParaBrakfrm.brakeimg = null
            this.paramdata.glbBikeParaLight.posimg = null
            this.paramdata.glbBikeParaBrakfrm.devimg = null
            this.paramdata.glbBikeParaBrakfrm.devcardimg = null
            this.paramdata.glbBikePara.retardant = null
            this.paramdata.glbBikePara.fireproof = null
            this.paramdata.glbBikePara.manual = null
        },
        handleSuccess(response, file, fileList){
            console.log(response.data)
            this.$Message.success({content:'上传成功', duration:3})
            if(response.data.fileclass == 'imgbrand'){
                this.paramdata.glbBikePara.imagebrand = response.data.filepath
            }
            else if(response.data.fileclass == 'bikeclassicimg'){
                this.paramdata.glbBikePara.bikeclassicimg = response.data.filepath
            }
            else if(response.data.fileclass == 'bikesimpleimg'){
                this.paramdata.glbBikePara.bikesimpleimg = response.data.filepath
            }
            else if(response.data.fileclass == 'bikesizeimg'){
                this.paramdata.glbBikePara.bikesizeimg = response.data.filepath
            }
            else if(response.data.fileclass == 'motorimg'){
                this.paramdata.glbBikeParaEc.motorimg = response.data.filepath
            }
            else if(response.data.fileclass == 'cellimg'){
                this.paramdata.glbBikeParaEc.cellimg = response.data.filepath
            }
            else if(response.data.fileclass == 'transimg'){
                this.paramdata.glbBikeParaTrans.transimg = response.data.filepath
            }
            else if(response.data.fileclass == 'sysimg'){
                this.paramdata.glbBikeParaTrans.sysimg = response.data.filepath
            }
            else if(response.data.fileclass == 'frmimg'){
                this.paramdata.glbBikeParaBrakfrm.frmimg = response.data.filepath
            }
            else if(response.data.fileclass == 'brakeimg'){
                this.paramdata.glbBikeParaBrakfrm.brakeimg = response.data.filepath
            }
            else if(response.data.fileclass == 'glbBikeParaLight.posimg'){
                this.paramdata.glbBikeParaLight.posimg = response.data.filepath
            }
            else if(response.data.fileclass == 'glbBikeParaBrakfrm.devimg'){
                this.paramdata.glbBikeParaBrakfrm.devimg = response.data.filepath
            }
            else if(response.data.fileclass == 'glbBikeParaBrakfrm.devcardimg'){
                this.paramdata.glbBikeParaBrakfrm.devcardimg = response.data.filepath
            }
            else if(response.data.fileclass == 'retardant'){
                this.paramdata.glbBikePara.retardant = response.data.filepath
            }
            else if(response.data.fileclass == 'fireproof'){
                this.paramdata.glbBikePara.fireproof = response.data.filepath
            }
            else if(response.data.fileclass == 'manual'){
                this.paramdata.glbBikePara.manual = response.data.filepath
            }
        },
        handleFormatError(response, file, fileList){
            this.$Message.error({content:'文件格式错误，请重新选择文件!', duration:3})
        },
        handleMaxSize(file, fileList){
            this.$Message.error({content:"文件过大，单个文件不能超过50M!", duration:3})
        },
        beforeUpload(){
            if(this.paramdata.glbBikePara.modelno == null || this.paramdata.glbBikePara.modelno == ''){
                this.$Message.error({content:"产品型号不能为空", duration:3})
                return false
            }
            return true
        },
        download(filepath){
            if(this.isnull(filepath)){
                this.$Message.error({content:'附件为空,无法查看!',duration:3})
                return
            }
            let openurl = window.location.protocol+"//"+window.location.host+filepath
            window.open(openurl, '_blank');
        },
        isnull(data){
            if(data ==null || data == ''){
                return true
            }
            else{
                return false
            }
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .ziliaoAdd {
        margin:0 auto;
    }
    #subdiv {
        margin: 0 auto;
    }
    .paramtbl {
        width: 100%;
        border: 1px solid green;

    }
    .paramtbl tr th td {
        border: 1px solid red;

    }

    .ivu-form .ivu-form-item {
        background: white;
        padding: 8px;
        margin-bottom: 1px;
    }
  </style>  